module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5384M8WF0Q"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1290},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","sk"],"defaultLanguage":"en","siteUrl":"http://localhost:8000/","generateDefaultLanguagePage":true,"redirect":true,"i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","sk"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/Project/:uid","getLanguageFromPath":true,"excludeLanguages":["en","sk"]},{"matchPath":"/:lang?/tag/:uid","getLanguageFromPath":true,"excludeLanguages":["en","sk"]},{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["en","sk"]},{"matchPath":"/:lang?/career/:uid","getLanguageFromPath":true,"excludeLanguages":["en","sk"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Blog","short_name":"Gatsby","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2fd96fa2b6b7bc6a658d2c72e675478b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://77b9fae5df86424e8e483a2604102e3d@test.sentry.adap.tw/28","release":"0.3.7","environment":"production"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
